
import Vue from "vue";

interface LangItem {
  icon: any;
  text: string;
  value: string;
}

export default Vue.extend({
  name: "LanguageSelect",

  props: {
    showText: {
      required: false,
      type: Boolean,
      default: false
    },
    languages: {
      required: true,
      type: Array,
      default: [] as Array<any>
    }
  },

  data: () => ({
    show: false as boolean,
    lang: { text: "Română", value: "ro" } as any
  }),

  watch: {
    lang: {
      immediate: true,
      handler() {
        // @ts-ignore
        this.$emit("input", this.lang.value);
      }
    }
  },

  computed: {
    icon() {
      return this.languages.find(item => item.value === this.lang);
    },
    flag() {
      return require(`@/assets/images/locales/${this.lang.value}.svg`);
    }
  },

  methods: {
    toggle(): void {
      this.show = !this.show;
    }
  }
});
