
import Vue from "vue";
import ImageViewer from "@/components/viewers/Image.vue";
import rules from "@/services/helpers/validation-rules";
import Allocation from "@/modules/orders/components/Allocation.vue";
import LanguageSelect from "@/modules/orders/components/LanguageSelect.vue";
import Footer from "@/components/navigation/Footer.vue";

export default Vue.extend({
  name: "Create",

  components: {
    Footer,
    LanguageSelect,
    Allocation,
    ImageViewer,
    ata: () => import("../components/order_types/ATA/Create.vue"),
    exp: () => import("../components/order_types/EXP/Create.vue"),
    aaij: () => import("../components/order_types/AAIJ/Create.vue"),
    cert: () => import("../components/order_types/CERT/Create.vue"),
    eval: () => import("../components/order_types/EVAL/Create.vue")
  },

  data: () => ({
    rules,
    usaidLogo: require("@/assets/images/logos/usaid.png"),
    berdLogo: require("@/assets/images/logos/berd-logo.png"),
    language: null as any,
    currentComponent: null as any,
    carnetLanguages: [
      {
        icon: require("@/assets/images/locales/ro.svg"),
        text: "Română",
        value: "ro"
      },
      {
        icon: require("@/assets/images/locales/ru.svg"),
        text: "Русский",
        value: "ru"
      },
      {
        icon: require("@/assets/images/locales/en.svg"),
        text: "English",
        value: "en"
      }
    ] as Array<any>,
    breadcrumb: [
      {
        text: "orders.title",
        disabled: false,
        href: "/orders"
      },
      {
        text: "orders.form.header.create",
        disabled: true,
        href: `/orders/edit`
      }
    ],
    services: [] as Array<any>,
    activeComponent: "services",
    serviceId: null,
    logos: {
      ata: require("@/assets/images/cci/ata.png") as any
    },
    allocations: {
      branch_id: null as any | number,
      subdivision_id: null as any | number,
      handler_id: null as any | number
    } as any,
    branches: [] as Array<SelectComponentInterface>,
    subdivisions: [] as Array<SelectComponentInterface>,
    handlers: [] as Array<SelectComponentInterface>,
    forceKey: 0 as number,
    items: [
      {
        icon: require("@/assets/images/locales/ro.svg"),
        text: "Română",
        value: "ro"
      },
      {
        icon: require("@/assets/images/locales/ru.svg"),
        text: "Русский",
        value: "ru"
      },
      {
        icon: require("@/assets/images/locales/en.svg"),
        text: "English",
        value: "en"
      }
    ] as Array<any>,
    showUsaid: false as boolean,
    allowedServices: ["aaij", "cert", "ata", "exp", "eval"] as Array<string>,
    usaidServices: ["aaij", "cert"] as Array<string>,
    client: {} as any,
    userOrderCounts: {} as any
  }),

  computed: {
    currentRole() {
      return this.$store.getters["user/currentRole"];
    },
    user() {
      return this.$store.getters["user/info"];
    }
  },

  async mounted() {
    await this.loadData();
    await this.getBeneficiary();
    await this.getUserOrderCounts();
  },

  methods: {
    async getBeneficiary() {
      try {
        this.client = await this.$API.users().getClient(this.user.id);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    setHover(event: boolean): void {
      this.showUsaid = event;
    },
    disabled(): boolean {
      return (
        !this.serviceId ||
        !this.language ||
        !this.allocations.branch_id ||
        !this.allocations.subdivision_id
      );
    },
    showOrder(): void {
      this.activeComponent = "component";
    },
    selectComponent(component: any) {
      if (this.canSelectService(component.alias)) {
        this.currentComponent = component;
        this.serviceId = component.id;
      }
    },
    async loadData(): Promise<void> {
      try {
        this.services = await this.$API.serviceTypes().getListWithFilters();
        this.branches = await this.$API.branches().getList();

        if (this.services.length === 1) {
          const [firstService] = this.services;
          this.selectComponent(firstService);
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    closeDialog() {
      this.activeComponent = "services";
      this.currentComponent = null;
    },
    getColor(color: any, defaultColor = ""): string {
      if (color.id === this.serviceId) {
        return color.color;
      }
      return defaultColor;
    },
    setAllocations(allocations: any): void {
      this.allocations = allocations;
      this.forceKey++;
    },
    canSelectService(alias: string): boolean {
      return this.allowedServices.some((item: any) => item === alias);
    },
    canViewUsaid(alias: any) {
      return this.usaidServices.includes(alias);
    },
    async getUserOrderCounts() {
      try {
        this.userOrderCounts = await this.$API
          .orders()
          .getUserOrderCounts(this.user.id);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
