
import Vue from "vue";

export default Vue.extend({
  name: "ImageViewer",

  props: {
    src: {
      required: true
    },
    size: {
      type: Number,
      required: false,
      default: 28
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false
    },
    showPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    dialog: false as boolean
  }),

  methods: {
    toggleDialog(): void {
      if (!this.src) {
        return;
      }
      this.dialog = !this.dialog;
    }
  }
});
